import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LandingPageService } from 'src/app/services/landing-page.service';
import * as Global from 'src/app/global';
import { ToastrService } from 'ngx-toastr'
import { Title } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  Global = Global;
  pricingForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
    private landingPageService: LandingPageService,
    private toastr: ToastrService, 
    private titleService: Title,
    private viewportScroller: ViewportScroller) {
      this.titleService.setTitle("HRMlix - Pricing");
    this.pricingForm = formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required,Validators.email])],
      phone_no: [null, Validators.compose([Validators.required,Validators.pattern("[0-9 ]{10}")])],
      subject: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([])]
    })
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  
  }

 

  addEnquiry(event: any) {
    this.pricingForm.markAllAsTouched();
    setTimeout(function () {
      Global.scrollToQuery("p.error-element");
    }, 100)

    if (this.pricingForm.valid) {
      event.target.classList.add('btn-loading');
      this.landingPageService.addEnquiry({
        'name': this.pricingForm.value.name,
        'email': this.pricingForm.value.email,
        'phone_no': this.pricingForm.value.phone_no,
        'subject': this.pricingForm.value.subject,
        'description': this.pricingForm.value.description
      }).subscribe((res: any) => {
        //console.log(res.message, 'message');

        if (res.status == 'success') {
          this.toastr.success(res.message);
          this.cancelEntry()

        } else if (res.status == 'val_err') {
          this.toastr.error(Global.showValidationMessage(res.val_msg));
        } else {
          this.toastr.error(res.message);
        }
        event.target.classList.remove('btn-loading');

      }, (err) => {
        event.target.classList.remove('btn-loading');

        this.toastr.error(Global.showServerErrorMessage(err));
      });
    }
  }

  cancelEntry() {
    Global.resetForm(this.pricingForm);
  }

}
