import { Component, OnInit } from '@angular/core';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  support: any;
// payload: any;
  searchText: any;
  is_load!:boolean;
  payload= {
    'searchkey': '',
    'faq_type': 'support'
      }

  supportForm: FormGroup = new FormGroup({
    searchkey: new FormControl('')
  })

  constructor(private landingPageService: LandingPageService , private titleService: Title,
    private viewportScroller: ViewportScroller,
    private spinner: NgxSpinnerService
  ) { 
   
  }
  
  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.titleService.setTitle("HRMlix - Support");
    
        
        this.fetchFaq(this.payload)
  }

  searchHelpTopics(searchkey: any = '') {
   const  payload:any = {
  'searchkey': searchkey,
  'faq_type': 'support'
    }

    this.fetchFaq(payload)
    //console.log(searchkey, 'sear key');
  
  }

  

  fetchFaq(payload:any){
    this.is_load = true;
    this.landingPageService.fetchFaq(payload).subscribe((res: any) => {
      //console.log(res, 'result');
      if (res.status == 'success') {
        
        this.support = res?.doc
        this.is_load =false;
        //console.log(this.support);
      }
    })

  }
}
