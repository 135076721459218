<section class="topspace sec-space about-page" >
    <div class="container my-lg-4"> 
        <div class="row align-items-center justify-content-center"> 
            <div class="col-md-5 me-auto">
                <div class="pricetop-text section-heading mw100 text-start">

                    <h2>HRMlix Pricing</h2>
                    <p>Introducing Ivan’s top product HRMLix which is powered by several cutting-edge features that streamline the entire HR management process by maximising employee acquisition. The pricing model has been tailored to meet the workforce management requirements of businesses of different sizes, right from startups to large enterprises. Experience the power-packed HRM solution of Ivan Infotech at competitive rates.  </p>
                    <ul>
                        <li>With our present model, at the moment we are offering the solution at Rs 115 (excluding GST) per employee each month.</li>
                        <li>We will introduce new models and additional features from time to time and the prices will be updated accordingly.</li>
                        <li>We also offer an on-premise solution.</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6"> 
                <div class="form-wrap" id="pricingForm">  
                    <form [formGroup]="pricingForm">
                        <div class="text-center section-heading mb-4">  
                            <h2>Get In Touch</h2>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <input formControlName="name" type="text" class="form-control" placeholder="Enter Your Name">
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'name', 'required')">This field is required</p>
                            </div>
                            <div class="col-md-6 mb-4">
                                <input formControlName="email" type="text" class="form-control" placeholder="Enter Your Email">
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'email', 'required')">This field is required</p>
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'email', 'email')">Enter a valid Email</p>
                            </div>
                            <div class="col-md-6 mb-4">
                                <input formControlName="phone_no" type="text" class="form-control" placeholder="Enter Your Number">
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'phone_no', 'required')">This field is required</p>
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'phone_no', 'pattern')">Enter a valid Phone Number</p>
                            </div>
                            <div class="col-md-6 mb-4">
                                <input formControlName="subject" type="text" class="form-control" placeholder="Enter Your Subject">
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'subject', 'required')">This field is required</p>
                            </div>
                            <div class="col-md-12">
                                <textarea formControlName="description" class="form-control" cols="30" rows="10"></textarea>
                                <p class="error-element" *ngIf="!Global.isInputRuleValid(pricingForm, 'description', 'required')">This field is required</p>
                            </div>
                            <div class="col-md-12 mt-4 text-center">
                                <button class="btn btn-primary" [disabled]="!pricingForm.valid" (click)="addEnquiry($event)"><span>Submit</span></button>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</section>
<!-- FAQ  -->

<!-- Cloud Solution  -->
<section class="cloud sec-space">
    <div class="container">
        <div class="cloud-wrapper">
            <div class="row align-items-center g-0">
                <div class="col-lg-4">
                    <h3><strong>Securing</strong> your company data with <strong>Google Cloud</strong></h3>
                </div>
                <div class="col-lg-4">
                    <figure class="clud-img">
                        <img src="../../../assets/images/cloud-logo.png" alt="">
                    </figure>
                </div>
                <div class="col-lg-4">
                    <div class="cloud-text">
                        <p>We assure your <strong>data security</strong> through our <strong>native cloud solution</strong>. To enhance data
                            protection, we've implemented multiple security layers both on the application and
                            server sides.</p> 
                            
                        <p>HRMlix operates on GCP with <strong>auto-scaling</strong> capabilities, and we exclusively
                            store all data within the <strong>India</strong> region.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>