import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent {
  constructor(private titleService: Title,
    private viewportScroller: ViewportScroller
  ){
    this.titleService.setTitle("HRMlix - HRMlix Delivery");
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
