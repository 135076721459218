import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent {
  constructor(private titleService: Title,
    private viewportScroller: ViewportScroller
  ){
    this.titleService.setTitle("HRMlix - Terms-Conditions");
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
