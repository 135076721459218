 <!-- Features  -->
 <section class="features feature-page sec-space">
    <div class="container">
        <div class="section-heading">
            <h5>FEATURES</h5>
            <h2>Simplify Talent Management with HRMlix </h2>
            <p>HRMlix's wide range of features will streamline your HR processes, boost employee engagement, and optimise talent management for your company's success. Experience the potential of the HRMlix platform.</p>
        </div>
        <div class="feature-wrapper">
            <!-- Feature 1  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-1.jpg" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Full Fledged Employee Information Manage</h3>
                        <p>HRMlix enables HR professionals to efficiently manage full-fledged employee information for their organization. Our system centralises employees’ personal details, job histories, benefits, and performance records, enhancing data accuracy and enabling informed decisions, enabling a seamless employee management experience.</p>
                        <ul>
                            <li>Offers comprehensive employee profiles, streamlines HR tasks, tracks progress</li>
                            <li>Keeps employee information current through automated updates </li>
                            <li>Automates repetitive tasks seamlessly </li>
                            <li>Frees up your HR team's valuable time for strategic initiatives</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 2  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-2.jpg" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Dynamic Departmental Appraisal Process Define</h3>
                        <p>HR teams can benefit from a dynamic departmental appraisal process that revolutionises performance assessments. This feature can be tailored to your company’s unique structure. It fosters transparency, accountability, and improved performance alignment within each department. </p> 
                        <ul>
                            <li>Allows for customised evaluations at the department level</li>
                            <li>Enables managers to set department-wise goals, track progress and provide feedback</li>
                            <li>Streamlines your appraisal process, drives departmental excellence</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 3  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-3.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Systematic ATS And Onboarding Of Employees</h3>
                        <p>HR professionals can use our systematic Application Tracking and Onboarding of employees feature to ease their work. From the moment a candidate applies, our system efficiently manages the entire process, enabling a seamlessly transition from candidates into employees.</p>
                        <p>Our onboarding module helps HR provide new employees with a welcoming and organised introduction to their company. </p>
                        <ul>
                            <li>Simplifies recruitment by build and organising your candidate pool  </li>
                            <li>Tracks applications, schedules interviews, defines the stages of interview process</li>
                            <li>Guides new hires through paperwork, training, and orientation</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 4  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-4.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Yearly Holiday Management/Weekly Off</h3>
                        <p>With an intuitive interface HRMlix simplifies Yearly Holiday Management and Weekly Off scheduling. Say goodbye to manual tracking and scheduling conflicts – our HRMS streamlines the process, ensuring a well-balanced work-life for your employees.</p>
                       <ul>
                            <li>Plans and manages yearly holidays and weekly offs efficiently</li>
                            <li>Tracks absenteeism and identifies leave-taking patterns</li>
                            <li>Defines company-specific custom holiday calendars </li>
                            <li>Manages employee leave requests and approvals</li>
                       </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 5  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-5.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Leave Rule Define For Leave Management</h3>
                        <p>You can take advantage of this feature to define leave rules with precision. Our HRMS simplifies leave management, reduces errors, and ensures fairness in leave allocation, contributing to a more efficient and employee-friendly HR process. </p>
                        <ul>
                            <li>Defines various leave types, displaying available and consumed leaves</li>
                            <li>Automates leave calculations and eligibility criteria</li>
                            <li>Aligns leave policies seamlessly according to employee and business needs</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 6  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-6.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Dynamic Policy Package Assigned To Employee For Salary</h3>
                        <p>We offer a robust Dynamic Policy Package for smooth compensation management that adheres to both company and government regulations. This module streamlines compensation structuring, ensuring accurate and fair practices, guaranteeing that your employees receive competitive and compliant compensation packages.</p>
                        <ul>
                            <li>Streamlines the compensation process (salary adjustments, bonuses, benefits)</li>
                            <li>Configures compensation aligned to company policies and government compliance</li>
                            <li>Links salary changes with performance review results</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 7  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-7.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Attendance Register And Regularisation</h3>
                        <p>Thanks to our Attendance Register and Regularisation feature you will be able to efficiently monitor employee attendance in a hassle-free manner. This feature of HRMlix is designed to simplify payroll processing, improves workforce management, and maintains transparent attendance data, facilitating compliance with labor regulations.</p>
                        <ul>
                            <li>Tracks employee attendance (check-ins, check-outs, breaks)</li>
                            <li>Enables accurate records of employee working hours</li>
                            <li>Allows employees to request attendance regularisations</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 8  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-8.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Employee Self-Service Portal</h3>
                        <p>The Employee Self-Service Portal, offers employees quick and convenient access to essential HR-related information. This feature promotes transparency, autonomy, and efficiency, enabling employees to manage their HR-related tasks with ease. HRMlix provides your workforce with a user-friendly self-service experience, enhancing engagement among your workforce. </p>
                        <ul>
                            <li>Enables employees to check leave requests status and attendance</li>
                            <li>Helps employees track pending and consumed leaves</li>
                            <li>Gives access to onboarding and policy documents, HR notifications/alerts</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Feature 9  -->
            <div class="row align-items-center feature-row">
                <div class="col-md-6">
                    <figure class="feature-img">
                        <img src="../../../assets/images/feature-9.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="feature-text">
                        <h3>Performance Management Plan And KPI Reports</h3>
                        <p>The advanced Performance Management module of our HRMlix system offers a streamlined interface that fosters a culture of continuous improvement for your business. This feature enhances accountability, aligns employee goals with organisational objectives, and streamlines performance evaluation processes.</p>
                        <ul>
                            <li>Facilitates the creation and tracking of performance plans and KPIs</li>
                            <li>Enables managers to set objectives, track progress, and provide feedback</li>
                            <li>Generates insightful KPI reports, enabling data-driven decision-making</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</section>

<!-- FAQ  -->
<!-- <section class="faq sec-space">
    <app-faq [faq_type]="'features'" [searchkey]="''"></app-faq>
     
</section> -->