<section class="topspace sec-space about-page">
    <div class="container">
        <div class="text-center section-heading">
            <h5>ABOUT</h5>
            <h2>About HRMlix</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-md-5">
                <figure class="feature-img">
                    <img src="../../../assets/images/about-us-graphics.png" alt="">
                </figure>
            </div>
            <div class="col-md-7">
                <div class="feature-text">
                    <p>Transform your business with HRMlix, a product of Ivan Infotech that enables you to create an effective, flexible, and people-centred workplace. Revolutionise your business workforce management ensuring optimal efficiency and higher productivity with the right HRMS product. Powered with a user-friendly interface and resilient features, HRMLix being a centralised platform enables you to manage the complete employee data along with the payroll processing and performance evaluations with KPI. With the help of Ivan Infotech’s all-inclusive software solution, you can boost staff engagement and management while making data-driven decisions.  Optimise talent management for your company's success by effortlessly tracking all the employee records while managing leaves and attendance. With our comprehensive, flexible, and adaptable solution designed to match the changing demands of your organisation, you will experience HR management's convenience like never before with HRMLix</p>
                    <ul>
                        <li>With several integral features, HRMLix enhances your company processes and equips the human resources division with useful tools.</li>
                        <li>Secure data encryption and integrity of your critical information according to the highest industry standards.</li>
                        <li>HRMlix offers a user-friendly self-service experience to your employees, thereby improving employee engagement.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- FAQ  -->
<!-- <section class="faq sec-space">
    <app-faq [faq_type]="'features'" [searchkey]="''"></app-faq>
  </section> -->
<!-- Cloud Solution  -->
<section class="cloud sec-space">
    <div class="container">
        <div class="cloud-wrapper">
            <div class="row align-items-center g-0">
                <div class="col-lg-4">
                    <h3><strong>Securing</strong> your company data with <strong>Google Cloud</strong></h3>
                </div>
                <div class="col-lg-4">
                    <figure class="clud-img">
                        <img src="../../../assets/images/cloud-logo.png" alt="">
                    </figure>
                </div>
                <div class="col-lg-4">
                    <div class="cloud-text">
                        <p>We assure your <strong>data security</strong> through our <strong>native cloud solution</strong>. To enhance data
                            protection, we've implemented multiple security layers both on the application and
                            server sides.</p> 
                            
                        <p>HRMlix operates on GCP with <strong>auto-scaling</strong> capabilities, and we exclusively
                            store all data within the <strong>India</strong> region.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>