import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as Global from 'src/app/global';
@Injectable({
  providedIn: 'root'
})
export class WebService {

  readonly BASE_URL;
  constructor(
    private http: HttpClient,    
  ) {
    this.BASE_URL = Global.BACKEND_URL;
  }

  postFormData(uri: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    return this.http.post<any>(`${this.BASE_URL}/${uri}`, formData);
  }

  post(uri: string, payload: any) {
    return this.http.post<any>(`${this.BASE_URL}/${uri}`, payload);
  }

  // post(uri: string, payload:any) {
  //   return this.http.get<any>(`${this.BASE_URL}/${uri}`, payload);
  // }

}
