<section class="topspace sec-space delivery-page about-page">
    <div class="container">
        <div class="section-heading text-start mw100">
            <h2>Privacy Policy: Ivan Infotech</h2>
        </div> 
        <h4>HRMlix IS COMMITTED TO SAFEGUARD EVERY PRIVACY ESSENTIALS</h4>
        <p>HRMlix is a cloud-based SaaS platform. It is completely <b>owned, designed, developed and maintained by Ivan Infotech Pvt. Ltd.</b> Ivan Infotech Pvt. Ltd., respects your concern regarding safety and security for all sorts of Personal Information collected from you. Read our privacy policy carefully to know about our information gathering, its uses and its distribution processes. By using our HRMlix software, you agree to the terms of this Privacy Policy, and your continued use of the Software constitutes your ongoing agreement to it.</p>
        <h4>WE ARE STRICT ABOUT EVERYONE’S PRIVACY</h4>
        <p>Ivan Infotech Pvt. Ltd.,values your concern regarding the dealing of personal information that you provided us or we collected. This Policy will help you to understand how you can control its distribution and use. It also puts proper light on our ethical practices regarding information collected through links or any other means. Customer data, contact information, sale data, financial data and digital data generated by HRMlix software are strictly confidential to us. Feel free to refer to this Policy in case you want to have detailed knowledge on this ground.</p>
        <h4>INFORMATION SHARING WITH THIRD PARTIES</h4>
        <p>We are conscious and strict on this ground. The channels through which we collect information for various ethical business purposes may provide links to various non-Ivan Infotech websites, social networks, applications and more. The third-party connection may occur due to the same. However, those situations are beyond our control. You have the option to refuse to provide personal information when prompted. Additionally, you may stop some forms of automated data gathering by using your operating system or web browser options. However, HRMlix softwaremight not be available or work to its full potential if you decide not to supply or permit information that is required for it. Read our privacy policy carefully before using our products.</p>
        <h4>THE INFORMATION WE COLLECT REMAINS SAFE & SECURED IN EVERY WAY</h4>
        <p class="ql-align-justify">At a point, we may collect information through interaction with you or through analytics and also through your usage of our business website, identifiable individuals and others. No matter what the mode or technique is; we guarantee to keep it safe, protected and secured. People associated with Ivan Infotech Pvt. Ltd, its products, will by no means use such information in any unlawful manner.</p>
        <p class="ql-align-justify">Besides, you need to understand that all your personal information is not restricted to:</p>
        <ul>
        <li>Physical address</li>
        <li>Email addresses</li>
        <li>Your name</li>
        <li>Website usage behaviour</li>
        <li>Demographic attributes</li>
        <li>Telephone numbers</li>
        <li>Past transaction behaviour</li>
        <li>Third-party information</li>
        </ul>
        <p class="ql-align-justify">We may also collect other information depending upon business requirements (even if it doesn't reveal your definite identity) through cookies, Internet Protocol or IP address and more. In every way, we assure you to guard the integrity and sensibility of your information. But, Ivan Infotech Pvt. Ltd., is not liable or is warranted for any kind of illegitimate details or information delivered by you. We would like you to ascertain that you will remain sincere while delivering all such personal information to us. Make sure that you remain in touch with us on a timely basis while doing business. We assure you to do our part while protecting your privacy and hope you will do your part too. Try to remove all inaccuracies at the earliest from your end. Take note: We grant information through strict privacy agreements. We also take adequate action to every kind of legal process that results in a legitimate IT service delivery. Moreover, we also respect the importance to use information for investigation, illegal activities, fraud, potential threats and more.</p>
        <p>&nbsp;</p>
        <h4>WHAT WE DO WITH THE INFORMATION</h4>
        <p class="ql-align-justify">Such sorts of information are essential to understand your business requirements and deliver better service. Other reasons are:</p>
        <ul>
        <li>Internal records</li>
        <li>For our business betterment</li>
        <li>To keep you updated with special offers or others that may be advantageous for you</li>
        <li>For market research purposes</li>
        <li>For smoother contact between you and us</li>
        <li>For software customizing/upgrading requirements</li>
        </ul>
        <p>&nbsp;</p>
        <h4>OUR PROCESS OF INFORMATION COLLECTION</h4>
        <p class="ql-align-justify">Remember, Ivan Infotech Pvt. Ltd., may collect personal and any other sorts of information from various sources. Those are:</p>
        <ul>
        <li><strong>Direct interactions </strong>- Through your use of HRMlix products and also interactions with us</li>
        <li><strong>Third parties </strong>- Automated interactions</li>
        <li><strong>Technologies</strong> - Electronic communication, cookies, URLs, and widgets</li>
        </ul>
        <p class="ql-align-justify">However, our automated interactions may change depending on time, technological updates, and others.</p>
        <p class="ql-align-justify">&nbsp;</p>
        <h4>COOKIES</h4>
        <p class="ql-align-justify">A cookie is information bits sent by a web server that can be stored on a web browser (in the form of information, identifiers, user preferences, general usage or statistical information). This is to help the browser to read it back. Ivan Infotech's server may also do the same with your browser to find and identify cookies. Often, cookies remain on users' computers even after leaving the website. You can always remove those according to your requirements. Your browser will have complete control of such cookies. Things that you can control in this context are:</p>
        <ul>
        <li>Cookie preferences</li>
        <li>Flash cookies</li>
        <li>Embedded URLs</li>
        <li>Physical location</li>
        </ul>
        <p class="ql-align-justify">&nbsp;</p>
        <h4>OBJECTIVITY & OTHER DETAILS OF POLICY</h4>
        <p class="ql-align-justify">Ivan Infotech Pvt. Ltd., endows your trust and confidence in us. We guarantee to prevent any sort of unauthorized disclosure and completely safeguard your personal information in the best possible way. So, we follow sound security practices for each of our valuable clients and also our product users. We assure you that use all your details fairly and lawfully. In certain cases, all your personal information will be collected after your complete approval only. It can also be reviewed by the information provider (whenever required and requested). Remain assured that the information that you provide will be kept secure in every possible way.</p>
        <p class="ql-align-justify">We promise that we won't disclose, transfer, or publish any of your crucial personal information with any other, without your complete consent. Besides, you have the complete right to opt out. Remember, the authenticity of the information provided by you is entirely yours. Ivan Infotech Pvt. Ltd., by no means, can be held responsible for its accuracy.</p>
        <p class="ql-align-justify">&nbsp;</p>
        <p class="ql-align-justify">&nbsp;</p>
        <h4>TAKE NOTE:</h4>
        <p class="ql-align-justify">Privacy Policy of Ivan Infotech Pvt. Ltd., may change or get modified from time to time depending upon the then market conditions and business requirements. We will notify you and keep you updated whenever it gets done and will ensure that all your prior rights get reserved even after the changes. For further details contact us @ <a href="tel:9147322049">91-9147322049</a> or <a href="mailto:enquiry@hrmlix.com">enquiry@hrmlix.com</a></p>
    </div>
</section>