import { Component } from '@angular/core';
import { Router } from '@angular/router'
import { LandingPageService } from 'src/app/services/landing-page.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(private router: Router, private landingService:LandingPageService){}

  navigate(){
    this.router.navigate(['/pricing'])
    setTimeout(()=>{
      this.landingService.triggerScroll();
    },100)

    
  }

  
}
