<div class="container">
    <div class="section-heading">
        <h5>FAQS</h5>
        <h2>Explore our amazing features</h2>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-9">
            <div class="faq-wrapper">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item" *ngFor="let feature of features; let i = index" >
                   <h2 class="accordion-header" [id]="'heading' + (i + 1)">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + (i + 1)" aria-expanded="true" [attr.aria-controls]="'collapse' + (i + 1)">
                            {{feature?.title}}
                        </button>
                      </h2>
                      <div [id]="'collapse' + (i + 1)" class="accordion-collapse collapse" [ngClass]="{'show': i + 1 === 1}" [attr.aria-labelledby]="'heading' + (i + 1)" [attr.data-bs-parent]="'#accordionExample'">
                        <div class="accordion-body">
                            <p>{{feature?.description}}</p>
                          <!-- <p>An HR management system is required to streamline HR processes, increase efficiency, and improve data accuracy. It also ensures legal compliance and provides better communication between HR, managers, and employees.It is used to manage various human resources functions, including hiring and onboarding, employee data management, payroll, and performance evaluation. It streamlines tasks, automates processes, and improves communication and collaboration between HR, managers, and employees.</p> -->
                        </div>
                      </div>

                    </div>
                    <!-- <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Why is the HR management system required?
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>An HR management system is required to streamline HR processes, increase efficiency, and improve data accuracy. It also ensures legal compliance and provides better communication between HR, managers, and employees.It is used to manage various human resources functions, including hiring and onboarding, employee data management, payroll, and performance evaluation. It streamlines tasks, automates processes, and improves communication and collaboration between HR, managers, and employees.</p>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Which is the best HR software?
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>An HR management system is required to streamline HR processes, increase efficiency, and improve data accuracy. It also ensures legal compliance and provides better communication between HR, managers, and employees.It is used to manage various human resources functions, including hiring and onboarding, employee data management, payroll, and performance evaluation. It streamlines tasks, automates processes, and improves communication and collaboration between HR, managers, and employees.</p>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            What is HRMS?
                          </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p>An HR management system is required to streamline HR processes, increase efficiency, and improve data accuracy. It also ensures legal compliance and provides better communication between HR, managers, and employees.It is used to manage various human resources functions, including hiring and onboarding, employee data management, payroll, and performance evaluation. It streamlines tasks, automates processes, and improves communication and collaboration between HR, managers, and employees.</p>
                        </div>
                        </div>
                      </div> -->
                  </div>
            </div>
        </div>
    </div>
</div>