<div class="home-page">
     <!-- Banner  -->
     <section class="banner-main">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="banner-text text-center">
                        <h1>A Complete HR Solution From <strong>Hire to Retire</strong>.</h1>
                        <p>From automation of people processes to creating an engaged and driven culture, HRMlix is all
                            you need to build a good to great company.</p>
                        <div class="mt-3 mt-lg-4">
                            <!-- <a routerLink="/" class="btn btn-outline-primary me-3">Sign Up for Free</a> -->
                            <a routerLink="/pricing" class="btn btn-primary">Request Demo</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-10">
                    <figure class="banner-img text-center">
                        <img src="../../../assets/images/company-dashboard.png" alt="company-dashboard">
                    </figure>
                </div>
            </div>
        </div>
    </section>
    <!-- USP  -->
    <section class="usp sec-space position-relative">
        <div class="container">
            <div class="section-heading">
                <h2>What distinguishes HRMlix from the rest?</h2>
                <p>Distinguish your HR operations from the competition with our innovative HRMlix, thanks to a comprehensive list of unique selling points that set us apart in the market.</p>
            </div>
            <div class="usp-wrapper">
                <div class="row align-items-center justify-content-between usp-row">
                    <div class="col-md-6 col-lg-5 pe-lg-5 mb-md-0 mb-4 usp-scroll">
                        <div class="nav flex-column nav-pills usp-tab me-3" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                aria-selected="true"><img src="../../../assets/images/policy-builder.png" alt="">Dynamic Policy Builder</button>
                            <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile" type="button" role="tab"
                                aria-controls="v-pills-profile" aria-selected="false"><img src="../../../assets/images/employee-unique-compliance.png" alt="">Employee-Unique Compliance</button>
                            <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-messages" type="button" role="tab"
                                aria-controls="v-pills-messages" aria-selected="false"><img src="../../../assets/images/audit-financial-excellence.png" alt="">Audit and Financial
                                Excellence</button>
                            <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-settings" type="button" role="tab"
                                aria-controls="v-pills-settings" aria-selected="false"><img src="../../../assets/images/pre-loaded-compliance.png" alt="">Pre-loaded Compliance</button>
                            <button class="nav-link" id="v-pills-fifth-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-fifth" type="button" role="tab" aria-controls="v-pills-fifth"
                                aria-selected="false"><img src="../../../assets/images/pro-tax.png" alt="">Pro-Tax Advance Reporting</button>



                                <button class="nav-link" id="v-pills-six-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-six" type="button" role="tab" aria-controls="v-pills-six"
                                aria-selected="false"><img src="../../../assets/images/Unaltered-Salary-Data.png" alt="">Unaltered Salary Data</button>
                                <button class="nav-link" id="v-pills-seven-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-seven" type="button" role="tab" aria-controls="v-pills-seven"
                                aria-selected="false"><img src="../../../assets/images/efficient-hiring-ats.png" alt="">Efficient Hiring With ATS</button>
                                <button class="nav-link" id="v-pills-eight-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-eight" type="button" role="tab" aria-controls="v-pills-eight"
                                aria-selected="false"><img src="../../../assets/images/OKR.png" alt="">OKR</button>
                                <button class="nav-link" id="v-pills-nine-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-nine" type="button" role="tab" aria-controls="v-pills-nine"
                                aria-selected="false"><img src="../../../assets/images/full-cycle.png" alt="">Full-Cycle Workforce Integration</button>
                                <button class="nav-link" id="v-pills-ten-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-ten" type="button" role="tab" aria-controls="v-pills-ten"
                                aria-selected="false"><img src="../../../assets/images/rest-api.png" alt="">REST API: ERP and 3rd Party Integrations</button>
                                <button class="nav-link" id="v-pills-eleven-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-eleven" type="button" role="tab" aria-controls="v-pills-eleven"
                                aria-selected="false"><img src="../../../assets/images/custom-modules.png" alt="">Custom Modules, Seamless Integration</button>
                                <button class="nav-link" id="v-pills-twelve-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-twelve" type="button" role="tab" aria-controls="v-pills-twelve"
                                aria-selected="false"><img src="../../../assets/images/SaaS.png" alt="">SaaS and on-premises Assistance</button>
                                <button class="nav-link" id="v-pills-thirteen-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-thirteen" type="button" role="tab" aria-controls="v-pills-thirteen"
                                aria-selected="false"><img src="../../../assets/images/SMTP-Email-Setup.png" alt="">SMTP Email Setup</button>


                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6">
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                aria-labelledby="v-pills-home-tab">
                                <figure class="usp-img">
                                    <img src="../../../assets/images/usp/Dynamic-Policy-Builder.png" alt="">
                                </figure>
                                <p>In today's dynamic business environment, the need for agility and efficiency in policy management is paramount. Ivan Infotech’s cutting-edge platform has been designed to empower organisations like yours by offering a streamlined approach to multi-policy and rule handling. </p>
                                
                                <ul>
                                    <li>Customise as per your company rules.</li>
                                    <li>Create, personalise, and implement policies effortlessly.</li>
                                    <li>Fast and easy policy management for every user.</li>                                        
                                </ul>
                            </div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                aria-labelledby="v-pills-profile-tab">
                                <figure class="usp-img">
                                    <img src="../../../assets/images/usp/Employee-Unique-Compliance.png" alt="">
                                </figure>
                                <p>Our platform empowers you to craft compliance policies that are as unique as your employees themselves.  It's your one-stop solution for developing a compliant, productive, and engaged workforce that will ultimately lead to better success and sustainability for your business. </p>
                                <ul>
                                    <li>Minimises administrative hassles, streamlines procedures, and promotes an open and accountable culture. </li> 
                                    <li>Customises compliance for each employee.</li> 
                                    <li>Gain the power to tailor compliance policies to match the specific requirements of each team member.</li> 
                                                                               
                                </ul>
                            </div>
                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                                aria-labelledby="v-pills-messages-tab">
                                <figure class="usp-img">
                                    <img src="../../../assets/images/usp/Audit-and-Financial-Excellence.png" alt="">
                                </figure>
                                <p>In the constantly changing world of financial management, the ability to obtain precise data and streamline audit procedures is the key to success. With HRMSlix, you can completely revolutionise audits and financial calculations in your company. </p> 
                                <ul>
                                    <li>Speeds up the auditing process while maintaining accuracy and compliance.</li>
                                    <li>120+ reports and advanced audit capabilities.</li>
                                    <li>Navigate the intricate world of finance with confidence and ease.</li>
                                </ul>
                            </div>
                            <div class="tab-pane fade" id="v-pills-settings" role="tabpanel"
                                aria-labelledby="v-pills-settings-tab">
                                <figure class="usp-img">
                                    <img src="../../../assets/images/usp/Pre-loaded-Compliance.png" alt="">
                                </figure>
                                <p>This platform is specifically designed for SMEs and enterprises, enabling easy integration and adherence to specific company legal requirements and government regulations.</p>
                                <ul>
                                    <li>Easy adaptation to the special requirements of organisations of all sizes and sectors.</li>
                                    <li>Preloaded Indian Government Compliance module</li>
                                    <li>Adheres to recent regulatory standards established by Indian authorities. </li>
                                    <li>User-friendly interface and customisable features.</li>                                        
                                </ul>
                            </div>
                            <div class="tab-pane fade" id="v-pills-fifth" role="tabpanel"
                                aria-labelledby="v-pills-fifth-tab">
                                <figure class="usp-img">
                                    <img src="../../../assets/images/usp/Pro-Tax-Advance-Reporting.png" alt="">
                                </figure>
                                <p>HRMSLix by Ivan Infotech is engineered to simplify even the most intricate tax scenarios. With comprehensive features tailored for advanced taxation, you can confidently navigate complex tax codes and regulations. Our portal paves the way for the future and redefines how you approach employee tax management and financial reporting.</p>
                                <ul>
                                    <li>Advanced taxation and reporting solutions catered to your employees' requirements </li>
                                    <li>Seamless integration of tax calculations. </li>
                                    <li>Ensures accurate withholdings and compliance with ever-evolving tax codes.</li>
                                    <li>Offers in-depth analyses of employee tax data. </li>
                                    <li>Streamlines year-end procedures and audits.</li>                                        
                                </ul>
                            </div>

                            <div class="tab-pane fade" id="v-pills-six" role="tabpanel"
                            aria-labelledby="v-pills-fifth-tab">
                            <figure class="usp-img">
                                <img src="../../../assets/images/usp/Unaltered-Salary-Data.png" alt="">
                            </figure>
                            <p>Our HRM portal ensures that once data is input, it is locked in stone, guaranteeing accurate salary information for staff.  The essential feature of no data modification or overriding from our portal guarantees the preservation of your final salary data.</p>
                            <ul>
                                <li>Prevents unintended mistakes or illegal modifications. </li> 
                                <li>Preserves the accuracy of wage records.</li> 
                                <li>A trustworthy platform for precise payroll management.</li> 
                                <li>Determines and delivers exact employee compensation with confidence and transparency</li> 
                                                                           
                            </ul>
                        </div>


                        <div class="tab-pane fade" id="v-pills-seven" role="tabpanel"
                        aria-labelledby="v-pills-fifth-tab">
                        <figure class="usp-img">
                            <img src="../../../assets/images/usp/Efficient-Hiring-With-ATS.png" alt="">
                        </figure>
                        <p>Ivan’s Advanced Applicant Tracking System, or ATS feature, steps in, offering a comprehensive, one-stop solution for all your hiring needs. Our platform is designed to simplify and accelerate the requisition process. Gain from the integration with reputable recruiting portals like LinkedIn, Indeed, and Glassdoor to give your job postings the most visibility possible.</p>
                        <ul>
                            <li>The easy-to-use interface enables HR professionals to quickly assess candidates and make fact-based choices. </li> 
                            <li>A unified approach to hiring excellence </li> 
                            <li>Revolutionises your entire recruitment strategy. </li> 
                                                                       
                        </ul>
                    </div>


                    <div class="tab-pane fade" id="v-pills-eight" role="tabpanel"
                    aria-labelledby="v-pills-fifth-tab">
                    <figure class="usp-img">
                        <img src="../../../assets/images/usp/OKR.png" alt="">
                    </figure>
                    <p>In the competitive landscape of business, achieving success hinges on a clear understanding of your goals and the ability to measure your progress. HRMLix provides you with unmatched insight into your staff, ensuring that you remain competitive in today's business environment.</p>
                    <ul>
                        <li>360-degree KPI management solution that aligns with your business objectives. </li>
                        <li>Tracks employee efficiency, engagement, and performance conveniently on a single platform.</li>                                        
                    </ul>
                </div>


                <div class="tab-pane fade" id="v-pills-nine" role="tabpanel"
                aria-labelledby="v-pills-fifth-tab">
                <figure class="usp-img">
                    <img src="../../../assets/images/usp/Full-Cycle-Workforce-Integration.png" alt="">
                </figure>
                <p>In the dynamic landscape of human resources and talent management, seamlessly navigating an employee's entire journey within your organization is paramount. Our 'Hire to Exit Model Compatible' feature, which provides an extensive way of managing every stage of an employee's lifespan, comes into action.</p>
                <ul>
                    <li>Easily interacts with the feature of the hire-to-exit paradigm. </li>  
                    <li>Offers extensive support, beginning with the hiring of new employees and continuing through their final departure.</li>  
                    <li>Simplifies and streamlines each phase of the onboarding and exit journey.</li>  
                                                              
                </ul>
            </div>


            <div class="tab-pane fade" id="v-pills-ten" role="tabpanel"
            aria-labelledby="v-pills-fifth-tab">
            <figure class="usp-img">
                <img src="../../../assets/images/usp/REST-API ERP-and-3rd-Party-Integrations.png" alt="">
            </figure>
            <p>Ivan’s REST API Integration solution comes into play, offering a powerful tool for effortlessly integrating with your ERP system and third-party services. </p>
            <ul>
                <li>reliable APIs that link to your ERP system and a wide range of third-party services with ease.</li>
                <li>Increases productivity by ensuring that HR procedures perfectly blend with the rest of the business.</li>
                <li>Connects with crucial third-party solutions for payroll and benefits management.</li>
                <li>A robust and flexible REST API that simplifies the exchange of data between systems
                    </li>                                        
            </ul>
        </div>


        <div class="tab-pane fade" id="v-pills-eleven" role="tabpanel"
        aria-labelledby="v-pills-fifth-tab">
        <figure class="usp-img">
            <img src="../../../assets/images/usp/Custom-Modules-Seamless-Integration.png" alt="">
        </figure>
        <p>Our custom module development and integration service is designed with scalability and flexibility in mind. Adapt to changing business landscapes, add new functionalities, and stay ahead of the curve without the need for a complete software overhaul.  With HRMSLix, we can modify the on-premise solutions for you.</p>
        <ul>
            <li>Modifies the on-premise solutions for businesses.</li>
            <li>Seamless and smooth process through a centralised platform that enables sophisticated payroll module and personalised analytics dashboard.</li>
            <li>Customisation and integration to enhance HR administration and promote organizational success.  </li>                                        
        </ul>
    </div>


    <div class="tab-pane fade" id="v-pills-twelve" role="tabpanel"
    aria-labelledby="v-pills-fifth-tab">
    <figure class="usp-img">
        <img src="../../../assets/images/usp/SaaS-and-on-premises-Model.png" alt="">
    </figure>
    <p>Ivan’s platform provides options for both on-premises and cloud SaaS implementations. We can accommodate your preference for either the control of on-premise hosting or the scalability and accessibility of the cloud. </p>
    <ul>
        <li>Provides a consistent and user-friendly experience.</li>  
        <li>Ensures that your choice of deployment aligns seamlessly with your operational goals.</li>  
        <li>Offers seamless availability and performance that concentrate on maximising your activities </li>  
        <li>Always up-to-date and ready to meet your evolving business needs.
            </li>                                        
    </ul>
</div>


<div class="tab-pane fade" id="v-pills-thirteen" role="tabpanel"
aria-labelledby="v-pills-fifth-tab">
<figure class="usp-img">
    <img src="../../../assets/images/usp/smtp-email-setup.png" alt="">
</figure>
<p>With our user-friendly interface, configuring SMTP settings has never been easier. Say goodbye to the complexities of email server setup. Whether it's transactional emails, alerts, or important updates, you can trust that your communications will reach your audience without fail.</p>
<ul>
    <li>Ensures your email notifications are configured correctly and efficiently. </li>
    <li>Messages are delivered promptly and securely.</li>
    <li>Maintains clear and consistent communication with your employees.</li>                                        
</ul>
</div>








                        </div>


                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Video  -->
    <section class="hrm-video sec-space"> 
        <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
            <source src="../../../assets/video/hrmlix-video.mp4" type="video/mp4"> 
          </video> 
    </section>
    <!-- Features  -->
    <section class="features sec-space">
        <div class="container">
            <div class="section-heading">
                <h5>FEATURES</h5>
                <h2>Benefits you'll get from HRMlix</h2>
                <p>Experience seamless HR management with our cutting-edge HRMlix, offering a range of unique features and benefits to streamline your human resource operations.</p>
            </div>
            <div class="feature-wrapper">
                <div class="row align-items-center feature-row">
                    <div class="col-md-6">
                        <figure class="feature-img">
                            <img src="../../../assets/images/feature-1.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-md-6">
                        <div class="feature-text">
                            <h3>Full Fledged Employee Information Manage</h3>
                            <p>HRMlix enables HR professionals to efficiently manage full-fledged employee information
                                for
                                their organization. Our system centralizes personal details, job histories, benefits,
                                and
                                performance records, enhancing data accuracy and enabling informed decisions.</p>
                            <p>With our comprehensive employee profiles, streamline HR tasks, track progress, and
                                provide a seamless
                                employee experience. Our HRMS ensures employee information stays current through
                                automated
                                updates and seamlessly automates repetitive tasks, freeing up your HR team's valuable
                                time
                                for strategic initiatives.</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center feature-row">
                    <div class="col-md-6">
                        <figure class="feature-img">
                            <img src="../../../assets/images/feature-2.jpg" alt="">
                        </figure>
                    </div>
                    <div class="col-md-6">
                        <div class="feature-text">
                            <h3>Dynamic Departmental Appraisal Process Define</h3>
                            <p>HR teams can benefit from a dynamic departmental appraisal process that revolutionizes
                                performance assessments. Tailored to your company’s unique structure, it allows for
                                customized evaluations at the department level. Managers can set department-specific
                                goals,
                                track progress, and provide targeted feedback.</p>
                            <p>This feature fosters transparency,
                                accountability, and improved performance alignment within each department. With HRMlix
                                you
                                can streamline your appraisal process, drive departmental excellence, and enhance
                                overall
                                organizational effectiveness.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="text-center">
                <a routerLink="/" class="btn btn-outline-primary">View All Features</a>
            </div> -->
        </div>
    </section>
      <!-- Cloud Solution  -->
      <section class="cloud sec-space">
        <div class="container">
            <div class="cloud-wrapper">
                <div class="row align-items-center g-0">
                    <div class="col-lg-4">
                        <h3><strong>Securing</strong> your company data with <strong>Google Cloud</strong></h3>
                    </div>
                    <div class="col-lg-4">
                        <figure class="clud-img">
                            <img src="../../../assets/images/cloud-logo.png" alt="">
                        </figure>
                    </div>
                    <div class="col-lg-4">
                        <div class="cloud-text">
                            <p>We assure your <strong>data security</strong> through our <strong>native cloud solution</strong>. To enhance data
                                protection, we've implemented multiple security layers both on the application and
                                server sides.</p> 
                                
                            <p>HRMlix operates on GCP with <strong>auto-scaling</strong> capabilities, and we exclusively
                                store all data within the <strong>India</strong> region.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
