import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent {
  constructor(private titleService: Title,
    private viewportScroller: ViewportScroller
  ){
    this.titleService.setTitle("HRMlix - Refund-policy");
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
