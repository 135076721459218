import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {

  constructor(private webService:WebService) { }
  private triggerScrollEvent = new Subject<void>();

  triggerScrollEvent$ = this.triggerScrollEvent.asObservable();

  triggerScroll() {
    this.triggerScrollEvent.next();
  }
  
  addEnquiry(payload:any){
    return this.webService.postFormData(
      'landing-page/add-enquiry',
      payload
    );
  }

  fetchFaq(payload:any={}){
    return this.webService.post('landing-page/get-faq',payload)
  }
}
