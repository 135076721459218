<!-- Footer  -->
<footer class="footer-main">
    <div class="footer-top">
      <div class="container">
        <div class="footer-wrapper">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-6 mb-lg-0 mb-4">
                    <h4>Transform your Human Resource, regardless of your business size or industry.</h4>
                    <p>For all your HR and payroll related tasks you can count on us for robust automation and simplified processes.</p>
                    <a routerLink="/pricing" class="btn btn-secondary">Contact Us Today!</a>
                </div>
                <div class="col-lg-4">
                    <figure class="ftopimg">
                        <img src="../../../assets/images/footer-img.png" alt="Footer image">
                    </figure>
                </div>
            </div>
        </div>
        <div class="footer-menu">
            <div class="ft-social">
                <ul class="d-flex justify-content-center list-unstyled mb-0">
                    <li>
                        <a href="https://www.facebook.com/IvanInfotechPvtLtd" target="_blank"><i class="bi bi-facebook"></i></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/Ivaninfotalk" target="_blank"><i class="bi bi-twitter-x"></i></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/ivan-infotech-pvt-ltd" target="_blank"><i class="bi bi-linkedin"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/ivan.infotech/" target="_blank"><i class="bi bi-instagram"></i></a>
                    </li>
                </ul>
            </div>
            <div class="quick-link-box text-center">
                <ul>
                    <li><a routerLink="/features">Features</a></li>
                    <li><a routerLink="/pricing">Pricing</a></li>
                    <!-- <li><a routerLink="/about">About</a></li> -->
                    <li><a routerLink="/support">Support</a></li>
                </ul>
            </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="footer-bottom d-flex justify-content-between align-items-center">
            <p>Copyright © 2023 HRMlix | Powered By <a target="_blank"
                href="https://www.ivaninfotech.com/">Ivan Infotech Pvt Ltd</a></p>
            <ul class="list-unstyled p-0 m-0">
                <li><a routerLink="/termsconditions">Terms & Conditions</a></li>
                <li><a routerLink="/privacy">Privacy Policy</a></li>
                <li><a routerLink="/refund">Cancellation & Refund</a></li>
                <li><a routerLink="/delivery">HRMlix Delivery</a></li>
            </ul>
      </div>    
    </div>
  </footer>