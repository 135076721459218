import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent {
  constructor(private titleService: Title,
    private viewportScroller: ViewportScroller
  ){
    this.titleService.setTitle("HRMlix - Privacy-policy");
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
