 <!-- Header  -->
 <header id="myHeader">
    <div class="menumain">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand brand-logo" routerLink="/">
                    <img src="../../../assets/images/logo.png" alt="logo">
                </a> 
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <a routerLink="\." class="menulogo d-lg-none"><img src="../../../assets/images/logo.png" alt="logo"></a>
                    <ul class="navbar-nav mx-auto align-items-center">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/features">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/pricing">Pricing</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" routerLink="/about">About</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/support">Support</a>
                        </li>
                    </ul>
                </div>

                <div class="navbar-text">
                    <a href="{{LOGIN_URL}}" target="_blank" class="btn">Login</a>
                    <a routerLink="/pricing" class="btn btn-outline-primary">Request Free Trial</a>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            </nav>
        </div>
    </div>
</header>