import { Component, OnInit } from '@angular/core';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { Title } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  features:any;
  docs:any;

  constructor(private landingPageService: LandingPageService, 
    private titleService: Title,
    private viewportScroller: ViewportScroller){}
  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.titleService.setTitle("HRMlix - Features");
    // this.landingPageService.getFaq({}).subscribe((res:any)=>{
    //   console.log(res,'result');
    //   if (res.status == 'success') {
    //     this.docs = res?.doc
    //     this.features = this.docs.filter((feature:any) => feature.faq_type === 'features' && feature.status === 'active')
    //     console.log(this.features);
        
    //   }
      
    // })
  }
}
