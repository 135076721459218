import { Component, Input } from '@angular/core';
import { LandingPageService } from 'src/app/services/landing-page.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  features:any;
  // docs:any;
@Input() faq_type:any = null;
@Input() searchkey:any = null;

  constructor(private landingPageService: LandingPageService){}
  ngOnInit(): void {
    // console.log(this.faq_type);
    // console.log(this.searchkey,'sear');
    
    this.landingPageService.fetchFaq({'searchkey':this.searchkey,'faq_type': this.faq_type}).subscribe((res:any)=>{
      if (res.status == 'success') {
        this.features = res?.doc
        // this.features = this.docs.filter((feature:any) => feature.faq_type === 'features' && feature.status === 'active')
        // console.log(res);
        
      }
      
    })
  }

}
