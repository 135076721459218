import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
 LOGIN_URL!: string;

 constructor(){
  this.LOGIN_URL = environment.LOGINURL
  console.log(this.LOGIN_URL)

 }


}
