<section class="topspace sec-space delivery-page about-page">
    <div class="container"> 
        <div class="section-heading text-start mw100">
            <h2>Terms and Conditions for Sale of Products and Services</h2>
        </div>
        <p><strong>PLEASE READ THE FOLLOWING CAREFULLY BEFORE USING OUR PRODUCT(S): </strong></p>
        <p>This End-User License Agreement ("EULA") is a legal agreement between (a) you (either an individual or a single entity) and (b) INFOTECH PRIVATE LIMITED that governs your use of the HRMlix Software Product, owned, designed, developed and maintained by IVAN INFOTECH PRIVATE LIMITED. </p>
        <p>BY CLICKING "I AGREE/ACCEPT", OR BY TAKING ANY STEP TO INSTALL OR USE THE SOFTWARE PRODUCT, YOU (1) REPRESENT THAT YOU ARE OF THE LEGAL AGE OF MAJORITY IN YOUR STATE, PROVINCE JURISDICTION OF RESIDENCE AND, IF APPLICABLE, YOU ARE DULY AUTHORIZED BY YOUR EMPLOYER TO ENTER INTO THIS CONTRACT AND (2) YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA. IF YOU DO NOT ACCEPT THE EULA TERMS, DO NOT USE THE SOFTWARE PRODUCT.</p>
        <p>This Software License Agreement (hereinafter referred to as the “Agreement”), is by and between IVAN INFOTECH PRIVATE LIMITED (hereinafter referred to as the “Ivan” and/or “Licensor”), a company incorporated under the laws of India, having its registered office at EN 35, Walzen Building, Sector 5, Saltlake, Kolkata 700091 and Customer (hereinafter referred to as the “Licensee” and/or “Customer”).</p>
        <h4>Agreement</h4>
        <p>Accordingly, in consideration of the mutual promises and agreements contained herein and other goods and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Licensor and Customer agree as follows:</p>
        <p><strong>License Grant-</strong> Subject to the Terms And Conditions of this Agreement, Licensor hereby grants to the Customer a non-exclusive and non-transferable license to use the Software in accordance with the terms of this Agreement and such other terms as may be agreed to by the Licensor and Customer from time-to-time, for an unlimited number of users and/or concurrent devices and to load and store the said Software solely for its own business purposes or discussed amicably. “Concurrent device” is each terminal, monitor or input device being used to access network at a given point in time. The data centre for Ivan Infotech products is in India.  </p>
        <p>For the project/job/product/software, whole ownership of respective project/job/product/software will go for customer. If customer invest partial amount of project/job/product/software, then ownership will be mutually decided based on expertise area of respective party.</p>
        <p><strong>Delivery of Software-</strong>Licensor shall provide the Customer one executable copy or cloud account of the Software (including each improvement), by way of electronic transmission, for use by Customer and, upon satisfactory downloading, the Customer shall promptly acknowledge receipt of the software.</p>
        <p><strong>Price and Payments terms-</strong> The products are subjected to various payment plans and the customer can select the suitable plan, depending upon the requirement and start to get the benefits of the products that Ivan Infotech offers. </p>
        <p><strong>Term-</strong> There is no specific date, and hence the term of this Agreement will begin on the date of the day the product is being purchased and will continue for a term as per the package selected. Thereafter, the terms of this Agreement will be extended automatically for successive year terms unless either party hereto provides the other party with written notice of non-renewal.</p>
        <h4>Maintenance and Support</h4>
        <p><strong>Support Services:</strong> Ivan Infotech offers support services for the HRMlix product to its customers. This can include technical assistance, bug resolution, troubleshooting, and general inquiries related to the software. It may also specify the available support channels, such as email, phone, or a dedicated support portal.</p>
        <p><strong>Response Times:</strong> Our team at Ivan Infotech offers an estimated timeframe within which our team aims to respond to customer queries or issues. For instance, it could specify that non-urgent requests will receive a response within 24 hours, on business days.</p>
        <p><strong>Support Availability:</strong> We have specified support hours or days during which the we will respond to support inquiries. </p>
        <p><strong>Maintenance Obligations:</strong> Our Terms and Conditions include maintenance obligations of the software vendor. This includes keeping the software up to date, addressing critical bugs or security vulnerabilities, and ensuring compatibility with supported operating systems or platforms.</p>
        <p><strong>Termination of Support:</strong> The terms and conditions herein also declare that in certain specified circumstances, support services may be terminated. This can include non-payment of fees, violation of the terms and conditions, or the end of the software's lifecycle. It's important to outline the consequences of support termination, such as the discontinuation of access to support resources.</p>
        <p><strong>Confidentiality-</strong> Customer agrees that neither the customer, nor its agents or employees shall in any manner use, disclose or otherwise communicate any information with respect to HRMlix which might enable copying of all or a portion of the Software. Customer agrees to take all necessary action to protect the confidential and proprietary information included in the Software, including appropriate instruction and agreement with its employees.</p>
        <p>Cloud SaaS software, on-premises or other software licensor may capture system diagnostic or error logs or reference system data to debug or optimize the software solution. The Licensor shall not in any manner use, disclose any data that the software owns which will be the intellectual property of the client.</p>
        <p><strong>Title-</strong>Customer agrees that, as between Customer and Licensor, Licensor owns all copyright, trade secret, patent, trademark and other proprietary rights in and to the Software (which are owned and developed by Licensor), including all modifications and improvements thereto. </p>
        <p>Customer agrees and accepts that the use of the Software, in accordance with the terms herein, shall not, in any manner whatsoever, grant it any right, title, or interest in the Software or the intellectual property contained therein. Any and all modifications or improvements to the Software made by the employees of Customer or its agents having access to the Software (or resulting from their use of the Software) shall be for the benefit of the Licensor and the Licensor shall be the sole and exclusive owner of all modifications or improvements to the Software. Customer covenants that it shall not at any time do or cause any act or circumstance, directly or indirectly, contesting or in way impairing any part of Licensor’s right in or to the Software, whether during the subsistence of this Agreement or at any time thereafter.</p>
        <p><strong>Warranties-</strong> The software is provided to customer “as is, where is,” and licensor makes no warranties regarding the software, whether written, oral, expressed or implied, including but not limited to warranties as to merchantability or fitness for a particular purpose. Licensor can give warranty on software feature and performance as per customer need. Licensor shall not be liable for any loss of profits, loss of use, or interruption of business, or for indirect, special, consequential, incidental, or exemplary damages of any kind, whether arising due to the use of the software or under this agreement or otherwise.</p>
        <p><strong>General Provisions-</strong></p>
        <ul>
            <li>Assignment- This Agreement and all rights and obligations hereunder shall not be assignable by Customer except with the prior written consent of Licensor. A change in control of Customer shall be deemed an assignment subject to this Section. This Agreement shall be binding upon each party’s permitted successors and assigns.</li>
            <li>Liability- Neither party hereto shall be liable for special, indirect, incidental or consequential damages, whether arising from contract or negligence. No action or claim relating to this Agreement or the Software may be instituted more than one (1) year after the event giving rise to such action or claim.</li>
            <li>Entire Agreement; Amendment- This Agreement with its exhibits is the complete and exclusive agreement of the parties hereto and supersedes all other communications, oral or written, between the parties hereto relating to this Agreement’s subject matter. Any change to this Agreement shall not be valid unless it is in writing and signed by both parties hereto.</li>
            <li>Governing Law- This Agreement shall be governed by the laws of India, without regard to its conflict of law’s provisions.</li>
            <li>Waiver- Neither the failure nor any delay of either party hereto to exercise a right, remedy or privilege under this Agreement shall operate as a waiver thereof, nor shall any single or partial exercise of a right, remedy or privilege preclude any further exercise of the same.</li>
            <li>Severability- A determination that any provision of this Agreement is invalid, illegal or unenforceable shall not affect the enforceability of any other provision.</li>
            <li>Notices- All notices and other communications required under this Agreement shall be in writing and shall be deemed to have been received when personally delivered or may be sent by facsimile, registered mail by first class, postage prepaid, addressed as set forth at the end of this Agreement or such other addresses as the parties hereto may specify from time to time.</li>
            <li>Counterparts- This Agreement may be executed in any number of counterparts and by different parties hereto in separate counterparts, each of which when so executed and delivered shall be deemed to be an original and all of which taken together shall constitute but one and the same agreement.</li>
        </ul>
        <p>If you are a citizen of India, the governing law and forum shall be the laws and courts of your usual place of residence.</p>
        <p>If you are not a citizen of India, the governing law and forum shall be the laws and courts of your usual place of residence.</p>
    </div>
</section>