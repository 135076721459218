<main class="main-wrapper">
  <div class="main-header">
    <app-header></app-header>
  </div>
  <div class="main-body">
      <router-outlet></router-outlet>
  </div>
  <div class="main-footer">
    <app-footer></app-footer>
  </div>
</main>



