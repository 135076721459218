<!-- Support Head  -->
<section class="support-head">
    <div class="container-fluid"> 
        <div class="row align-items-end g-0"> 
            <div class="col-lg-3">
                <figure class="s-vector text-end">
                    <img src="../../../assets/images/s-vector1.png" alt="">
                </figure>
            </div>
            <div class="col-lg-6">
                <div class="section-heading">
                    <h5>SUPPORT</h5>
                    <h2>How can we help you?</h2>
                </div>
            </div>
            <div class="col-lg-3">
                <figure class="s-vector">
                    <img src="../../../assets/images/s-vector2.png" alt="">
                </figure>
            </div>
        </div>
    </div>
</section>


<!-- Help Topics  -->

<section class="help-topic sec-space">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-7 px-lg-5 px-xl-0">
                <div class="search-field position-relative">
                    <form [formGroup]="supportForm">
                        <input type="search" placeholder="Describe your issue here" class="form-control" formControlName="searchkey" #searchText (keyup)="searchHelpTopics(searchText.value)">
                        <button type="submit" class="search-icon me-4"><img src="../../../assets/images/search.png" alt="search" (click)="searchHelpTopics(searchText.value)"></button>
                        <!-- <button type="submit" class="search-icon ms-5"> <i class="bi bi-x-lg" (click)="searchHelpTopics(searchText.value)"></i>  </button> -->
                    </form>
                </div>
                <h3>Browse help topics</h3>
                <div class="help-wrapper">
                    <div class="accordion" id="accordionExample1">
                        <div class="d-flex justify-content-center" *ngIf="is_load">
                            <div class="spinner-border" role="status">
                            </div>
                        </div>
                    
                    
                        <div class="accordion-item" *ngFor="let sup of support; let i = index">
                          <h2 class="accordion-header" [id]="'heading' + (i + 1)"> 
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + (i + 1)" aria-expanded="true" [attr.aria-controls]="'collapse' + (i + 1)">
                              {{i + 1}}. {{sup?.faq_title}}
                            </button>
                          </h2>
                          <div [id]="'collapse' + (i + 1)" class="accordion-collapse collapse" [ngClass]="{'show': i + 1 === 1}" [attr.aria-labelledby]="'heading' + (i + 1)" [attr.data-bs-parent]="'#accordionExample'">
                            <div class="accordion-body">
                                <p>{{sup?.faq_description}}</p>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                                Account Security
                            </button>
                          </h2>
                          <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                Recover a recently deleted Account
                            </button>
                          </h2>
                          <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour1">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour1" aria-expanded="false" aria-controls="collapseFour1">
                                Secure a hacked or compromised
                              </button>
                            </h2>
                            <div id="collapseFour1" class="accordion-collapse collapse" aria-labelledby="headingFour1" data-bs-parent="#accordionExample1">
                              <div class="accordion-body">
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                              </div>
                            </div>
                          </div> -->
                          <!-- <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive1">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive1" aria-expanded="false" aria-controls="collapseFive1">
                                Why your account recovery request is delayed
                              </button>
                            </h2>
                            <div id="collapseFive1" class="accordion-collapse collapse" aria-labelledby="headingFive1" data-bs-parent="#accordionExample1">
                                <div class="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit</p>
                                </div>
                            </div>
                          </div> -->
                      </div>
                      <ng-container *ngIf="!support?.length && !is_load">
                        <div
                          colspan="100%"
                          class="text-center"
                          style="background-color: #f7f7f7"
                        >
                          No records found
                        </div>
                      </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Support Facility  -->
<div class="support-facility sec-space">
    <div class="container">
        <div class="row sup-row px-lg-5">
            <div class="col-lg-4">
                <div class="support-box">
                    <h4>Lifetime Free update and Support for Cloud SaaS Platform</h4>
                    <p>Benefit from lifetime free updates with our Cloud SaaS Platform, ensuring your business stays ahead with the latest features and improvements at no extra cost. For any information and issue, you can contact to our support – support&#64;hrmlix.com</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="support-box">
                    <h4>1 Year free support for On-premises solution</h4>
                    <p>Experience streamlined operations with our on-premises solutions, backed by 1 year of free support for seamless maintenance and assistance when you need it most.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="support-box">
                    <h4>3 Years of Free Updates for On-premises solution</h4>
                    <p>Get 3 years worth of free updates with our On-premises solutions, ensuring your system remains up-to-date and efficient without additional costs.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="support-box">
                    <h4>Connect Over Phone For Instant Support</h4>
                    <p>Ivan Infotech provides complete assistance by connecting over calls and offering instant support for the HRMlix in case you face any kind of issues with the product.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="support-box">
                    <h4>Instant Solution TAT Provide</h4>
                    <p>Gain proper customer support when requested on-call or by mail and raise an instant ticket, where you can get an instant TAT solution.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="support-box">
                    <h4>API Technical Support </h4>
                    <p>Our team of experts provides API technical support to ensure smooth integration, troubleshoot issues, and maximize the functionality of the solution.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Talk to us  -->
<section class="talk-to-us">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="talk-box">
                    <h3>Talk to us!</h3>
                    <p>Get in touch with our support team via email, call, or chat.</p>
                    <a href="mailto:support@hrmlix.com"  class="btn orange-btn d-flex align-items-center">Contact Support <i class="bi bi-arrow-right-short"></i></a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="talk-box">
                    <h3>Got a Question?</h3>
                    <p>Get answers from the experts from the HRMlix Support community.</p>
                    <a href="mailto:enquiry@hrmlix.com" class="btn orange-btn d-flex align-items-center">Contact Enquiry <i class="bi bi-arrow-right-short"></i></a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="talk-box">
                    <h3>Still can't find what you're looking for?</h3>
                    <p>Submit a request and we'll get back to you soon!</p>
                    <a href="tel:+9147322049" class="btn orange-btn d-flex align-items-center">Contact <i class="bi bi-arrow-right-short"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>