export const environment = {
    production: false,
    APIURL: 'https://demolandingbackend.hrmlix.com/api/landing-page-service',
    BASEHREF:'/',
    APPNAME: 'The Seamless HR Solution',
    LOGINURL:'https://demoind.hrmlix.com',
    DATATABLELENGTH:20,
    DATATABLELENGTHCHANGEMENU:[1, 10, 20, 50, 75, 100],
    USERAUTH:'',
    RAZORPAYKEY: 'rzp_test_XT2LimGxwnmnYW',
    RAZORPAYCOMPANYNAME: 'HRMlix',
    RAZORPAYCOMPANYLOGO:'https://cdn3.iconfinder.com/data/icons/inficons-currency-set/512/rupee-512.png',
    RAZORPAYCURRENCY:'INR'
  };
  