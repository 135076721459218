<section class="topspace sec-space delivery-page about-page">
    <div class="container">
        <div class="section-heading text-start mw100">
            <h2>Cancellation And Refund Policy </h2>
        </div> 
        <p>Thank you for choosing Ivan Infotech’s product HRMlix. We strive to provide you with the best experience possible. However, we understand that circumstances may arise where you may need to cancel your subscription or seek a refund. We offer a free demo before any kind of product subscription plan or license and you have to raise cancellation of the demo to the respective person or through email: <a href="mailto:support@hrmlix.com">support@hrmlix.com</a>.</p> 
        <p>We offer a standard level of free consultation for product-oriented needs to our customers, post that we offer a proposal, and on the agreed proposal we raise tax invoices to the customer. Once the invoice has been generated, you can raise a request to the respective sales/marketing person for any cancellation. Post invoice paid, you will be aligned with an Account Manager.</p> 
        <p>Once the payment has been processed, we are not entitled to make any kind of cancellation or refund of the transacted amount. For the products, there is a zero refund policy post-transaction. Talk to the concerned account manager and a mutual discussion would be generated about the work done to date and the final decision would be taken based on that. Please be aware that any customised or personalised services offered related to the product are not eligible for a refund.</p>
        <p>It is to be noted that you must submit the written documents for any cancellation and refund requests using our specified customer service methods, such as email. For us to fulfill your request as quickly as possible, kindly provide all pertinent information.</p>
        <p>This refund and cancellation policy is subject to change at any moment, with or without prior notice. You will be informed of any modifications either through our website or by email. You agree to abide by the conditions specified in this policy by using our services.</p>
        <p>Do not hesitate to get in touch with our customer support staff if you have any queries or need further information.</p>
         
    </div>
</section>