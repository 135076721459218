import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { FeaturesComponent } from './pages/features/features.component';
import { SupportComponent } from './pages/support/support.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { RefundComponent } from './pages/refund/refund.component';

const routes: Routes = [
{
  path: '',
  component: HomeComponent,
  data: { pageTitle: 'Home' }
},
{
  path: 'about',
  component: AboutComponent,
  data: { pageTitle: 'About' }
},
{
  path: 'features',
  component: FeaturesComponent,
  data: { pageTitle: 'Features' }
},
{
  path: 'support',
  component: SupportComponent,
  data: { pageTitle: 'Support' }
},
{
  path: 'pricing',
  component: PricingComponent,
  data: { pageTitle: 'Pricing' }
},
{
  path: 'privacy',
  component: PrivacyPolicyComponent,
  data: { pageTitle: 'Privacy Policy' }
},
{
  path: 'termsconditions',
  component: TermsConditionsComponent,
  data: { pageTitle: 'Terms Conditions' }
},
{
  path: 'delivery',
  component: DeliveryComponent,
  data: { pageTitle: 'Delivery' }
},
{
  path: 'refund',
  component: RefundComponent,
  data: { pageTitle: 'Refund' }
},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
